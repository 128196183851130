<template>
  <iframe ref="iframe" class="reward" :src="rewardUrl"></iframe>
</template>

<script>
export default {
  name: "Reward",
  props: {
    lang: {
      type: String,
    },
    sid: {
      type: Number,
    },
    rid: {
      type: String,
    },
    uuid: {
      type: String,
    },
  },
  data() {
    return {
      reward: "",
    };
  },
  computed: {
    rewardUrl() {
      return `${process.env.VUE_APP_ADMIN_API}/api/reward-render?sid=${this.sid}&rid=${this.rid}&uuid=${this.uuid}&lang=${this.lang}`;
    },
  },
  async mounted() {
    const frame = this.$refs.iframe;
    document.documentElement.style["overflow-y"] = "hidden";
    frame.contentDocument.documentElement.innerHTML = this.reward;
    frame.contentDocument.documentElement.style["overflow-x"] = "hidden";
    this.$emit('ready', true)
  },
  beforeDestroy() {
    document.documentElement.style["overflow-y"] = "";
  },
};
</script>

<style scoped>
.reward {
  z-index: 10;
  width: 100vw;
  height: 100vh;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  border: none;
}
</style>
